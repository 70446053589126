// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-sobre-mi-js": () => import("./../../../src/pages/sobre-mi.js" /* webpackChunkName: "component---src-pages-sobre-mi-js" */),
  "component---src-templates-all-categories-js": () => import("./../../../src/templates/allCategories.js" /* webpackChunkName: "component---src-templates-all-categories-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

